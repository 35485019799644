import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import Events from './pages/Events';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import AddEvent from './pages/AddEvent';
import EventLayout from './layouts/dashboard/EventLayout';
import EditEvent from './pages/EditEvent';
import HotelLayout from './layouts/dashboard/HotelLayout';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { 
          path: 'events',
          element: <EventLayout />,
          children:[
            { element: <Navigate to="/dashboard/events/all" />, index: true },
            { path: 'all', element: <Events /> },
            {
              path:'add',
              element: <AddEvent />
            },
            {
              path:'edit/:eventId',
              element: <EditEvent />
            },
            {
              path:':eventId',
            },
          ] 
        },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
