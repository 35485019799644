import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
// eslint-disable-next-line import/no-unresolved
import AuthService from '../../../service/auth';
import Iconify from '../../../components/iconify';
import { signUserFailure, signUserStart, signUserSuccess } from '../../../features/authSlice';



// ----------------------------------------------------------------------

export default function LoginForm() {
  const {isLoading,loggedIn} = useSelector(state=>state.auth);
  const errRef = useRef()
  const [showPassword, setShowPassword] = useState(false);
  const [errMsg, setErrMsg] = useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const [user, setUser] = useState({
    username:"",
    password:""
  })

  const inputHandler = (e) => {
    const {name, value} = e.target
    setUser(prevUser => ({...prevUser, [name]:value}))
  }
 const {username, password} = user

  const handleClick = async(e) => {
    e.preventDefault()
    dispatch(signUserStart())
    const user = {username,password}
    try {
    const response = await AuthService.userLogin(user)
    dispatch(signUserSuccess(response))
    navigate('/')
    } catch (error) {
    dispatch(signUserFailure(error.response.data.errors))
    }
       
  };


  const content = false ? <Typography variant='p'>Loading...</Typography> :
  <>
  <Stack spacing={3}>
    <Typography variant='p' ref={errRef} className={errMsg ? "show": "none"}>{errMsg}</Typography>
    <TextField 
      name="username" 
      label="Username" 
      value={username}
      onChange={inputHandler}
      autoComplete="off"
      required
      />

    <TextField
      name="password"
      label="Password"
      type={showPassword ? 'text' : 'password'}
      value={password}
      onChange={inputHandler}
      autoComplete='off'
      required
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
              <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  </Stack>

  <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
    <Checkbox name="remember" label="Remember me" />
    <Link variant="subtitle2" underline="hover">
      Forgot password?
    </Link>
  </Stack>

  <LoadingButton fullWidth size="large" type="submit"  disabled={isLoading} variant="contained" onClick={handleClick}>
  {isLoading ? "loading..." : "Login"}
  </LoadingButton>
</>
  return content
}
