import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { ToastContainer, toast } from 'react-toastify';

import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
  Box,
  Modal,
  ImageList,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import {  getMebelsStart, getMebelsSuccess, patchMebelsStart } from '../features/mebelSlice';
import MebelService from '../service/mebel';
import { getItem } from '../helpers/per-storage';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Mebel', alignRight: false },
  { id: 'model', label: 'Model', alignRight: false },
  { id: 'subtitle', label: 'Mebel type', alignRight: false },
  { id: 'role', label: 'Type', alignRight: false },
  { id: 'new', label: 'New $', alignRight: false },
  { id: 'old', label: 'Old $', alignRight: false },
  { id: 'sale', label: 'Sale %', alignRight: false },
  // { id: 'size', label: 'Size', alignRight: false },
  { id: 'img', label: 'Image', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: '' },
];
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const dispatch = useDispatch()
  const {mebels,isLoading} = useSelector(state=>state.mebel)
  const getMebels = async()=>{
    dispatch(getMebelsStart())
    try {
      const response = await MebelService.getMebel()
      dispatch(getMebelsSuccess(response))
    } catch (error) {
      console.log(error);
    }
  }
  const editData = (data) =>{
    dispatch(patchMebelsStart(data))
  }
  const deleteData = async (slug) =>{
    const token =getItem('token')
    try {
      await MebelService.deleteMebel(slug,token)
      getMebels()
      toast.success(" Ma'lumot o'chirildi !", {
        position: "top-right",
        theme: "dark",
        });
      handleCloseMenu()
    } catch (error) {
      toast.error(" Ma'lumot o'chirilmadi !", {
        position: "top-right",
        theme: "dark",
        });
    }

  }
  const [openm, setOpenm] = useState(false);
  const [opend, setOpend] = useState(false);
  const [modal,setModal] = useState() 
  const handleClose = () => setOpenm(false);
  const handleClosedes = () => setOpend(false);
  
  const modalImage = (data) =>{
    setModal(data)
    setOpenm(true)
  }

  const modalImagedes = (data) =>{
    setModal(data)
    setOpend(true)
  }


  useEffect(()=>{
    const token = getItem('token')
    getMebels()
   
  },[])
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);
  
  const [id, setId] = useState(null)

  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setId(id)
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = mebels.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - mebels.length) : 0;

  const filteredUsers = applySortFilter(mebels, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Dashboard | Bayir Admin</title>
      </Helmet>
      <Container>
      <ToastContainer />

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Mebels
          </Typography>
          <Button component={Link} variant="contained" to={"/dashboard/events/add"} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Mebel
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
         
            <TableContainer sx={{ minWidth: 800 }}>
            {isLoading && <Box sx={{ display: 'flex' }}  alignItems="center"
  justifyContent="center">
      <CircularProgress />
    </Box>}
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={mebels.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                
                <TableBody>
                
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, name, mebel,images, size ,type , subtitle, old, sale, description } = row;
                    const mainImage = images.length === 0 ? "https://images.pexels.com/photos/19036765/pexels-photo-19036765/free-photo-of-horse.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load" : images[0].image  ;
                    const selectedUser = selected.indexOf(id) !== -1;
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                       
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={name} src={mainImage} />
                            <Typography variant="subtitle2" noWrap>
                              {mebel}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left"><strike>{(subtitle===null || subtitle==='') ? 'Нет типа' : subtitle }</strike></TableCell>
                        <TableCell align="left">{type}</TableCell>
                        <TableCell align="left">{row.new} so'm</TableCell>
                        <TableCell align="left"><strike>{(old===null || old==='') ? 0 : old } so'm</strike></TableCell>
                        <TableCell align="left">{(sale===null || sale==='') ? 0 : sale }%</TableCell>

                        {/* <TableCell align="left">{size}</TableCell> */}
                        <TableCell align="left">
                        <Button variant="contained" onClick={()=>modalImage(mainImage)}>Image</Button>
                        <Modal
  open={openm}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <img src={modal} alt='img'/>
  </Box>
</Modal>
                        </TableCell>
                        <TableCell align="left">
                        <Button variant="contained" onClick={()=>modalImagedes(description)}>Description</Button>
                          
                          <Modal
  open={opend}
  onClose={handleClosedes}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
  {/* <Typography id="modal-modal-title" variant="h6" component="h2"   /> */}
  <p dangerouslySetInnerHTML={{
     __html: modal,
  }} />
     
  </Box>
</Modal></TableCell>

                        

                    

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, id)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={mebels.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem component={Link}  to={`/dashboard/events/edit/:${id}`}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem component={Link}  onClick={()=>deleteData(id)}  sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
