import { createSlice } from '@reduxjs/toolkit'
import { setItem } from '../helpers/per-storage'

const initialState={
    isLoading:false,
    loggedIn:false,
    user:null,
    error:null,
}
export const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers:{
        signUserStart:state=>{
            state.isLoading = true
        },
        signUserSuccess:(state,action)=>{
            state.isLoading = false
            state.loggedIn = true
            state.user = action.payload
            setItem('token',action.payload.access)
        },
        signUserFailure:(state,action)=>{
            state.isLoading = false
            state.error = action.payload
        },
       logoutUser :state =>{
        state.user = null
        state.loggedIn = false
       }
    }
})
export const {signUserStart,signUserSuccess,signUserFailure,logoutUser} = authSlice.actions
export default authSlice.reducer