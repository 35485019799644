import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';

// import { useGetTodosQuery } from '../api/apiSlice';


export default function DashboardAppPage() {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };
 
 
  return (
    <>
      <Helmet>
        <title> Bayir mebel admin </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Bayir dashboard
          </Typography>
        </Grid>
      </Container>
    </>
  );
}
