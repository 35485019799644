import axios from "./api"

const AuthService = {
    async userLogin(user) {
        const {data} = await axios.post('/auth/token/', user)
        return data
    },
    async getUser() {
        const {data} = await axios.get('/user')
        return data
    },
}
export default AuthService