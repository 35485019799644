import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoading:false,
    mebels:[],
    error:null,
}
export const mebelSlice = createSlice({
    name:'mebel',
    initialState,
    reducers:{
        getMebelsStart: state =>{
            state.isLoading = true
        },
        getMebelsSuccess: (state,action)=>{
            state.isLoading = false
            state.mebels = action.payload
        },
        getMebelsFailure:(state,action)=>{
            state.error = action.payload
            state.isLoading = false
        },
       
        postMebelsStart:state =>{
            state.isLoading = true
        },
        postMebelsSuccess:state =>{
            state.isLoading = false
        },
        postMebelsFailure:state =>{
            state.isLoading = false
            state.error = 'Error'
        },
        patchMebelsStart:(state) =>{
            state.isLoading = true

        },
        patchMebelsSuccess:state =>{
            state.isLoading = false
        },
        patchMebelsFailure:state =>{
            state.isLoading = false
            state.error = 'Error'
        }
        
    }
})
export const {getMebelsStart,getMebelsSuccess,getMebelsFailure,postMebelsStart,postMebelsSuccess,patchMebelsFailure,patchMebelsStart,patchMebelsSuccess,postMebelsFailure,} = mebelSlice.actions
export default mebelSlice.reducer