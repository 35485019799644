import axios from './api';

const MebelService = {
  async getMebel() {
    const { data } = await axios.get('/api/products');
    return data;
  },
  async postMebel(mebel, token) {
    const { data } = await axios.post('/api/products', mebel, { headers: { Authorization: `Bearer ${token}` } });
    return data;
  },
  async deleteMebel(slug, token) {
    const { data } = await axios.delete(`/api/products/${slug}`, { headers: { Authorization: `Bearer ${token}` } });
    return data;
  },
  async editMebel(slug, mebel, token) {
    const { data } = await axios.patch(`/api/products/${slug}`, mebel, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  },
};
export default MebelService;
