import { configureStore } from '@reduxjs/toolkit'

import MebelReducer from "../features/mebelSlice"
import AuthReducer from "../features/authSlice"

export const store = configureStore({
  reducer: {
    mebel:MebelReducer,
    auth:AuthReducer
  },
  devTools: process.env.NODE_ENV !== 'production',
})