import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Input,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MebelService from '../service/mebel';
import { patchMebelsFailure, patchMebelsStart, patchMebelsSuccess } from '../features/mebelSlice';
import { getItem } from '../helpers/per-storage';
import Iconify from '../components/iconify';

const QuillSection = styled('div')(({ theme }) => ({
  width: '100%',
  // maxWidth: 480,
  height: 400,
}));

const initialState = {
  name: '',
  title: '',
  img: '',
  new: '',
  old: '',
  sale: '',
  size: '',
  subtitle: '',
  description: '',
  type: '',
  mebel: '',
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function EditEvent() {
  const { eventId } = useParams();
  const Idx = Number(eventId.split(':')[1]);
  const { mebels, isLoading } = useSelector((state) => state.mebel);
  const editMebel = mebels.find((c) => c.id === Idx);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const QuillRef = useRef(ReactQuill);
  const [state, setState] = useState(editMebel);
  const [image, setImage] = useState(null);
  const [images, setImages] = useState(mebels.find((c) => c.id === Idx).images);
  const [imageArr, setImageArr] = useState([]);
  const [selectedIdx, setSelectedIdx] = useState(null);

  // const imageHandler = (e) => {
  //   e.preventDefault();
  //   if (e.target.files?.length !== 0) {
  //     setImage(e.target.files);
  //   } else {
  //     setImage(null);
  //   }
  // };

  const imageHandler = (e) => {
    e.preventDefault();
    setImages(null);

    if (e.target.files?.length !== 0) {
      setImage(e.target.files);
      const arr = {};
      for (let i = 0; i < e.target.files.length; ) {
        console.log(e.target.files[i]);
        const reader = getImage(e.target.files[i]);
        reader.onload = () => {
          arr[`${i}`] = reader.result;
        };
        i += 1;
      }
      setTimeout(() => {
        console.log(e.target.files, arr);
        setImageArr({ ...arr });
        setImages(null);
        setSelectedIdx(0);
        setSelectedValue(e.target.files[0].name);
      }, 500);
    } else {
      setImage(null);
      setImages(editMebel.images);
      setImageArr([]);
      setSelectedIdx(null);
      setSelectedValue('');
    }
  };

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }],
        ['link', 'image'],
        ['clean'],
        [{ color: [] }],
      ],
    },
  };

  const createMebel = async (e) => {
    e.preventDefault();
    const token = getItem('token');

    const formData = new FormData();
    formData.append('name', state.name);
    formData.append('title', state.mebel);
    formData.append('new', state.new);
    formData.append('old', state.old);
    formData.append('sale', state.sale);
    formData.append('subtitle', state.subtitle);
    formData.append('type', state.type);
    formData.append('description', QuillRef.current.value);
    formData.append('mebel', state.mebel);
    formData.append('size', state.size);
    dispatch(patchMebelsStart());
    toast.info(" Ma'lumot o'zgartirilmoqda!", {
      position: 'top-right',
      theme: 'dark',
    });
    try {
      if (image === null) {
        await MebelService.editMebel(Idx, formData, token);
      } else {
        const imageArrr = Object.values({ ...image });
        const delImage = imageArrr.splice(selectedIdx, 1)[0];
        imageArrr.unshift(delImage);
        for (let i = 0; i < imageArrr?.length || 0; ) {
          formData.append('images', imageArrr[i]);
          i += 1;
        }
        await MebelService.postMebel(formData, token);
        await MebelService.deleteMebel(Idx, token);
      }
      dispatch(patchMebelsSuccess());
      navigate('/dashboard/events/all');
      toast.success(" Ma'lumot o'zgartirildi !", {
        position: 'top-right',
        theme: 'dark',
      });
    } catch (error) {
      dispatch(patchMebelsFailure());
      toast.error(" Ma'lumot o'zgartirilmadi !", {
        position: 'top-right',
        theme: 'dark',
      });
      console.log(error);
    }
  };

  const [selectedValue, setSelectedValue] = useState('');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    const imageArrr = Object.values({ ...image });
    const idx = imageArrr.findIndex((item) => item.name === event.target.value);
    setSelectedIdx(idx);
  };

  const getImage = (file) => {
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
    }

    return reader;
  };

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>Add Mebel</title>
      </Helmet>
      <Container>
        <form onSubmit={createMebel}>
          <Stack spacing={3}>
            <FormLabel
              sx={{
                color: '#000',
              }}
            >
              Mebel
            </FormLabel>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Mebel</InputLabel>
              <Select
                onChange={inputHandler}
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="mebel"
                value={state.mebel}
                label="Mebel"
                // onChange={handleChange}
              >
                <MenuItem value={'Столы для руководителей'}>Столы для руководителей</MenuItem>
                <MenuItem value={'Столы для руководителей | Open Varnish'}>
                  Столы для руководителей | Open Varnish
                </MenuItem>
                <MenuItem value={'Столы для сотрудников'}>Столы для сотрудников</MenuItem>
                <MenuItem value={'Столы для переговоров'}>Столы для переговоров</MenuItem>
                <MenuItem value={'Open Space'}>Столы Open Space</MenuItem>
                <MenuItem value={'журнальные столики'}>Журнальные столики</MenuItem>
                <MenuItem value={'Журнальные столики | Open Varnish'}>Журнальные столики | Open Varnish</MenuItem>
                <MenuItem value={'Ресепшн Стол'}>Столы Reception</MenuItem>
                <MenuItem value={'Модульная Мебель'}>Модульная Мебель</MenuItem>
                <MenuItem value={'Модульная Мебель | Open Varnish'}>Модульная Мебель | Open Varnish</MenuItem>
                <MenuItem value={'Модульная Мебель | Столы Open Space'}>Модульная Мебель | Столы Open Space</MenuItem>
                <MenuItem value={'Мягкая Мебель'}>Мягкая Мебель</MenuItem>
                <MenuItem value={'Шкафы'}>Шкафы</MenuItem>
                <MenuItem value={'Шкафы | Open Varnish'}>Шкафы | Open Varnish</MenuItem>
                <MenuItem value={'Комоды'}>Комоды</MenuItem>
                <MenuItem value={'Комоды | Open Varnis'}>Комоды | Open Varnis</MenuItem>
                <MenuItem value={'Кресла'}>Кресла</MenuItem>
                <MenuItem value={'Open Space'}>Столы Open Space</MenuItem>
                <MenuItem value={'Приставка'}>Приставка</MenuItem>
                <MenuItem value={'Комплект'}>Комплект</MenuItem>
              </Select>
            </FormControl>
            <FormLabel
              sx={{
                color: '#000',
              }}
            >
              Mebel (Современная/Классическая)
            </FormLabel>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Mebel (Современная/Классическая)</InputLabel>
              <Select
                onChange={inputHandler}
                name="subtitle"
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={state.subtitle}
                label="Mebel (Современная/Классическая)"
                // onChange={handleChange}
              >
                <MenuItem value={'Современная'}>Современная</MenuItem>
                <MenuItem value={'Классическая'}>Классическая</MenuItem>
                <MenuItem value={'no-type'}>Нет типа</MenuItem>
              </Select>
            </FormControl>
            <FormLabel
              sx={{
                color: '#000',
              }}
            >
              Mebelning turi
            </FormLabel>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Mebel Turi</InputLabel>
              <Select
                onChange={inputHandler}
                name="type"
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={state.type}
                label="Mebel turi"
                // onChange={handleChange}
              >
                <MenuItem value={'МДФ'}>МДФ</MenuItem>
                <MenuItem value={'ЛДСП'}>ЛДСП</MenuItem>
                <MenuItem value={'Солид'}>Солид</MenuItem>
                <MenuItem value={'no-type'}>Нет типа</MenuItem>
              </Select>
            </FormControl>
            <FormLabel
              sx={{
                color: '#000',
              }}
            >
              Mebelning modeli
            </FormLabel>
            {/* <Divider /> */}
            <TextField name="name" required label="Mebel modeli B-1805" onChange={inputHandler} value={state.name} />
            <FormLabel
              sx={{
                color: '#000',
              }}
            >
              Mebelning o'lchami
            </FormLabel>
            <TextField name="size" required label="Mebel o'lchami 300x400" onChange={inputHandler} value={state.size} />
            <FormLabel
              sx={{
                color: '#000',
              }}
            >
              Mebelning narxi
            </FormLabel>
            <TextField name="new" required label="Mebelning yangi narxi" onChange={inputHandler} value={state.new} />
            <TextField name="old" required label="Mebelning eski narxi" onChange={inputHandler} value={state.old} />
            <TextField name="sale" required label="Chegirma qiymati % da" onChange={inputHandler} value={state.sale} />
            <FormLabel
              sx={{
                color: '#000',
              }}
            >
              Mebelning rasmi
            </FormLabel>
            {/* <Divider /> */}

            {/* <Input
              onChange={imageHandler}
              label="Mebel header image url"
              type="file"
              inputProps={{ accept: 'image/*' }}
            /> */}

            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<Iconify icon="material-symbols:cloud-download-outline" />}
            >
              Mebel images
              <VisuallyHiddenInput type="file" onChange={imageHandler} multiple />
            </Button>

            {image !== null && image.length !== 0 && (
              <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                {Object.keys(image).map((item, key) => (
                  <Box key={key} sx={{ width: 200 }}>
                    <img src={imageArr[key + 1]} alt={item} style={{ width: 200, height: 175, objectFit: 'cover' }} />
                    <Box component="div" sx={{ display: 'flex', alignItems: 'flex-start', my: 1, gap: 1 }}>
                      <Radio
                        checked={selectedValue === image[item].name}
                        onChange={handleChange}
                        value={`${image[item].name}`}
                        name="radio-buttons"
                        sx={{ p: 0 }}
                      />
                      <Typography>{image[item].name}</Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
            {images !== null && images.length !== 0 && (
              <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                {images.map((item, key) => (
                  <Box key={key} sx={{ width: 200 }}>
                    <img src={item.image} alt={item.image} style={{ width: 200, height: 175, objectFit: 'cover' }} />
                    <Box component="div" sx={{ display: 'flex', alignItems: 'flex-start', my: 1, gap: 1 }}>
                      <Radio
                        checked={item.main}
                        // onChange={handleChange}
                        // value={`${image[item].name}`}
                        name="radio-buttons"
                        sx={{ p: 0 }}
                        disabled
                      />
                      <Typography style={{fontSize:'12px'}}>{item.image.slice(item.image.lastIndexOf('/') + 1)}</Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}

            <QuillSection
              sx={{
                my: 3,
              }}
            >
              <ReactQuill
                className="react-quill"
                ref={(element) => {
                  if (element != null) {
                    QuillRef.current = element;
                  }
                }}
                required
                theme="snow"
                modules={modules}
                value={state.description}
              />
            </QuillSection>
            <Box sx={{ my: 3 }} />
            <Button sx={{ mt: 3 }} fullWidth size="large" type="submit" variant="contained">
              Save Mebel
            </Button>
          </Stack>
        </form>
      </Container>
    </>
  );
}
