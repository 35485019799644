import { useEffect, useState } from 'react';
import { Outlet, useLocation, Navigate, } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// @mui
import { styled } from '@mui/material/styles';
//
// import { selectCurrentToken } from '../../features/authSlice';

import { useSelector } from 'react-redux';
import Header from './header';
import Nav from './nav';
import { getItem } from '../../helpers/per-storage';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
const {isLoading,loggedIn}=  useSelector(state=>state.auth);
  const token = getItem("token")
  const local = localStorage.getItem("token")
  const location = useLocation()

  return (
    loggedIn ?
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
    :
    <Navigate to={"/login"} state={{ from : location}} replace />
  );
}
